<template>
  <template v-if="isDataLoaded">
    <ErrorView v-if="isServerError" description="Ошибка сервера"/>
    <ErrorView v-else-if="!useWebApp().initData" description="Используйте Telegram клиент"/>

    <RestrictedView v-else-if="isRestricted" :description="restrictedDescription"/>

    <router-view v-else/>
  </template>
  <template v-else>
    <PreloaderView/>
  </template>
</template>

<script setup lang="ts">
import {useWebApp} from 'vue-tg';

import ErrorView from "@/views/ErrorView.vue";
import {getCurrentInstance, onBeforeMount, onBeforeUnmount, Ref, ref} from "vue";
import axios from "axios";
import {Analytics} from "@/analytics/Analytics";
import {AmplitudeAnalytics} from "@/analytics/AmplitudeAnalytics";
import PreloaderView from "@/views/PreloaderView.vue";
import RestrictedView from "@/views/RestrictedView.vue";
import {UserProperties} from "@/interfaces/users/UserPropertiesInterface";

const instance = getCurrentInstance();

const isDataLoaded = ref(false);
const isServerError = ref(false);

const isRestricted = ref(false);
const restrictedDescription = ref("");

onBeforeMount(async () => {
  const userProperties: Ref<UserProperties | undefined | null> = ref(null);

  const userData = getTelegramUserData();
  const analytics = setupAnalytics(process.env.VUE_APP_AMPLITUDE_TOKEN, userData);

  try {
    const responseUsersProperties = await getResponseContent(process.env.VUE_APP_USERSPROPERTIES_URL);
    isServerError.value = !responseUsersProperties.data;

    userProperties.value = (responseUsersProperties.data.filter((x: UserProperties) => x.telegram_id == userData.id))[0];

    isDataLoaded.value = true;
  } catch (e) {
    isDataLoaded.value = true;
  }

  if (userProperties.value != null) {
    isRestricted.value = true;
    restrictedDescription.value = userProperties.value.public_comment;

    if (!userProperties.value.is_trace)
      analytics.pause();

    analytics.sendEvent('Ban', {});
  }

  if (instance && instance.appContext) {
    instance.appContext.config.globalProperties.analytics = analytics;
  }

});

onBeforeUnmount(() => {
  localStorage.removeItem('tab');
});

async function getResponseContent(url: string) {
  return await axios.get(url, {
    headers: {'Content-Type': 'application/json'}
  });
}
function getTelegramUserData() {
  const params = new URLSearchParams(useWebApp().initData);
  const data = Object.fromEntries(params);

  return JSON.parse(data.user);
}
function setupAnalytics(token: string, userData: object) {
  const analytics: Analytics = new AmplitudeAnalytics(token);

  analytics.setUser(userData.id);
  analytics.setLanguage(userData.language_code);
  analytics.setIdentifyField("Telegram username", userData.username);

  return analytics;
}
</script>
<style lang="scss">
@font-face {
  font-family: 'SF Pro';
  src: url('@/assets/fonts/SFProDisplay-Bold.eot');
  src: local('SF Pro Bold'), local('SFProDisplay-Bold'),
  url('@/assets/fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
  url('@/assets/fonts/SFProDisplay-Bold.woff2') format('woff2'),
  url('@/assets/fonts/SFProDisplay-Bold.woff') format('woff'),
  url('@/assets/fonts/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('@/assets/fonts/SFProDisplay-Medium.eot');
  src: local('SF Pro Medium'), local('SFProDisplay-Medium'),
  url('@/assets/fonts/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
  url('@/assets/fonts/SFProDisplay-Medium.woff2') format('woff2'),
  url('@/assets/fonts/SFProDisplay-Medium.woff') format('woff'),
  url('@/assets/fonts/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('@/assets/fonts/SFProDisplay-Regular.eot');
  src: local('SF Pro Regular'), local('SFProDisplay-Regular'),
  url('@/assets/fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
  url('@/assets/fonts/SFProDisplay-Regular.woff2') format('woff2'),
  url('@/assets/fonts/SFProDisplay-Regular.woff') format('woff'),
  url('@/assets/fonts/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


html, body {
  font-family: 'SF Pro', sans-serif;
  height: 100%;
  margin: 0;
  scroll-behavior: auto !important;
  overscroll-behavior: none;
}

input, textarea, input::placeholder, textarea::placeholder {
  font-family: 'SF Pro', sans-serif;
}

a {
  text-decoration: none;
}

#app {
  height: 100%;
}
</style>


