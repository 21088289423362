<template>
  <div :class="theme + '-theme container blocked-page'">
    <img src="/assets/ban.svg" alt="Blocked Image" class="blocked-page__image"/>
    <h1 class="blocked-page__title">Доступ заблокирован</h1>
    <p class="blocked-page__subtitle">Кажется, кто-то допизделся</p>
  </div>
</template>

<script setup lang="ts">
import {defineProps, ref, toRefs} from "vue";
import {useWebAppTheme, useWebAppViewport} from "vue-tg";

const theme = useWebAppTheme().colorScheme;
const viewportHeight = useWebAppViewport().viewportStableHeight;
const descriptionDefault = "Обратитесь в @yscanchat, если считаете, что это ошибка";

const props = defineProps<{
  description: string
}>();
const {description} = toRefs(props);

const descriptionResult = ref("");
descriptionResult.value = description.value == "" ? descriptionDefault : description.value
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .blocked-page {
    background-color: themed("background-main");

    &__title {
      color: themed("text");
    }

    &__subtitle {
      color: themed("accent-gray");
    }
  }
}

.blocked-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 1rem;

  &__image {
    width: 15rem;
    height: auto;
    margin-bottom: 1.5rem;
  }

  &__title {
    font-size: 1.15rem;
    font-weight: bold;
    margin: 0;
  }

  &__subtitle {
    font-size: 1.1rem;
    margin-top: 0.2rem;
  }
}
</style>