<template>
  <div :class="theme + '-theme'">
    <LoadingOverlayComponent :show="isUploading" :progress="uploadingProgress"/>

    <div class="page">
      <div class="container top-container">
        <div class="header">
          <img src="/assets/logo.jfif" alt="Логотип" style="width: 3rem; height: 3rem; border-radius: 100rem">
          <p>Вы размещаете объявление в Scalebid</p>
        </div>
        <TabHeaderComponent v-on:tabSelected="selectTab"/>
      </div>

      <div class="container middle-container">
        <ImageUploadComponent :error="hardValidate" v-model:images="formDataObject.images"/>
      </div>

      <div class="container bottom-container">
        <TabContentComponent :active-tab="selectedTab"
                             :hard-validate="hardValidate"
                             v-model:location="formDataObject.location"
                             v-model:description="formDataObject.description"
                             v-model:price="formDataObject.price"/>

        <MainButton text="Отправить на проверку" @click="submitData"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {getCurrentInstance, onMounted, ref} from "vue";
import {MainButton, useWebApp, useWebAppHapticFeedback, useWebAppTheme, useWebAppViewport} from "vue-tg";
import TabHeaderComponent from "@/components/home/tab/TabHeaderComponent.vue";
import TabContentComponent from "@/components/home/tab/TabContentComponent.vue";
import ImageUploadComponent from "@/components/home/input/ImageUploadComponent.vue";
import {Analytics} from "@/analytics/Analytics";
import LoadingOverlayComponent from "@/components/home/LoadingOverlayComponent.vue";

const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const theme = useWebAppTheme().colorScheme;
const selectedTab = ref(0);
useWebAppViewport().expand();

const formDataObject = ref({
  images: [] as string[],
  location: '',
  price: 0,
  description: '',
  isSell: true
});

const hardValidate = ref(false);
const isUploading = ref(false);
const uploadingProgress = ref(0.0);

const submitData = async () => {
  if (isUploading.value) {
    return;
  }

  if (formDataObject.value.images.length === 0 || !formDataObject.value.description || !formDataObject.value.location || formDataObject.value.isSell && !formDataObject.value.price) {
    hardValidate.value = !hardValidate.value;
    return useWebAppHapticFeedback().impactOccurred('heavy');
  }

  useWebAppHapticFeedback().impactOccurred('soft');
  const formData = new FormData();

  formData.append('telegramId', useWebApp().initDataUnsafe.user.id || "-1");
  formData.append('telegramName', `${useWebApp().initDataUnsafe.user.first_name}${useWebApp().initDataUnsafe.user.last_name ? " " + useWebApp().initDataUnsafe.user.last_name : ""}` || "Аноним");

  formDataObject.value.images.forEach((image, index) => {
    const blob = base64ToBlob(image, 'image/png');
    formData.append(`images`, blob, `image_${index}.png`);
  });

  formData.append('location', formDataObject.value.location);
  formData.append('price', formDataObject.value.isSell ? formDataObject.value.price.toString() : "0");
  formData.append('description', formDataObject.value.description);
  formData.append('isSell', formDataObject.value.isSell ? "1" : "0");

  const xhr = new XMLHttpRequest();
  xhr.open("POST", `${process.env.VUE_APP_API_URL}/ad/submit`);

  xhr.upload.onprogress = (event) => {
    if (event.lengthComputable) {
      isUploading.value = true;
      uploadingProgress.value = event.loaded / event.total;
    }
  };

  xhr.onload = () => {
    useWebApp().close();
    analytics.sendEvent('Send button', {});
  };

  xhr.onerror = () => {
    console.error('An error occurred during the upload');
  };

  xhr.send(formData);
};


const base64ToBlob = (base64: string, contentType: string): Blob => {
  const byteCharacters = atob(base64.split(',')[1]);
  const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], {type: contentType});
};

const selectTab = (tab: number) => {
  selectedTab.value = tab;

  formDataObject.value.isSell = tab == 0;
};

onMounted(() => {
    const formData = new FormData();
    formData.append('telegramId', useWebApp().initDataUnsafe.user.id);

    const xhr = new XMLHttpRequest();
    xhr.open("PUT", `${process.env.VUE_APP_API_URL}/user/online`);
    xhr.send(formData);
});
</script>

<style lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .page {
    background-color: themed('background-secondary');
  }

  .container {
    background-color: themed('background-main');
  }

  .header {
    p {
      color: themed('text');
    }
  }
}

.page {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  min-height: 100vh;
}

.container {
  width: 100%;
  padding: 1.1rem;
  box-sizing: border-box;
}

.top-container {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.9rem;
    text-align: left;
    font-size: 0.9rem;
    margin-bottom: 0.8rem;

    p {
      width: 10rem;
    }
  }
}

.middle-container {
  padding-left: 0;
  padding-right: 0;

  border-radius: 1rem;
}

.bottom-container {
  flex-grow: 1;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
</style>