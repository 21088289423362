<template>
  <div class="categories">
    <button
        v-for="(category, index) in ['Продаю', 'Ищу']"
        :key="index"
        :class="{ active: selectedCategory === index }"
        @click="selectCategory(index)"
    >
      {{ category }}
    </button>
  </div>
</template>

<script setup lang="ts">
import {defineEmits, getCurrentInstance, ref} from 'vue';
import {Analytics} from "@/analytics/Analytics";

const emit = defineEmits(["tabSelected"]);
const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const selectedCategory = ref(0);

const selectCategory = (category: number) => {
  emit("tabSelected", category);
  selectedCategory.value = category;

  analytics.sendEvent("Tabs", {
    Tab: category === 0 ? "Sale" : "Buy"
  });
};
</script>


<style lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .categories {
    background-color: themed("background-secondary");

    button {
      background-color: themed("background-secondary");
      color: themed("accent-gray");

      &.active {
        background-color: themed("background-main");
        border: 0.5px solid themed("accent-gray-5");
        color: themed("text");
      }
    }
  }

}

.categories {
  display: flex;
  justify-content: space-between;
  padding: 1.9px 1.9px;
  border-radius: 12px;
  position: relative;
  z-index: 10;
  flex-shrink: 0;

  button {
    border: none;
    padding: 5px 20px;
    border-radius: 10px;
    font-size: 17px;
    font-weight: 510;
    cursor: pointer;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    flex: 1;
    text-align: center;

    &.active {
      flex: 1;
    }
  }
}
</style>
