<template>
  <div class="overlay" v-if="show">
    <div class="progress-container">
      <p class="title">Загружаем фотографии</p>
      <p class="subtitle">Нужно немного подождать</p>
      <div class="progress-bar">
        <div class="progress-fill" :style="{ width: progress * 100 + '%' }"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps<{
  show: boolean;
  progress: number;
}>();

if (props.progress < 0 || props.progress > 1) {
  console.warn('Progress should be between 0 and 1');
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .overlay {
    background-color: themed("overlay");
  }

  .progress-container {
    p {
      &.title {
        color: themed("accent-white");
      }

      &.subtitle {
        color: themed("accent-gray");
      }
    }
  }

  .progress-bar {
    background-color: themed("background-gray");
  }

  .progress-fill {
    background-color: themed("accent-white");
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.progress-container {
  text-align: center;
  width: 100%;
  max-width: 20rem;
  box-sizing: border-box;

  p {
    margin: 0;

    &.title {
      font-weight: 310;
      font-size: 1.1rem;
      margin-bottom: 0.12rem;
    }

    &.subtitle {

    }
  }
}

.progress-bar {
  width: 100%;
  height: 0.15rem;
  border-radius: 0.125rem;
  overflow: hidden;
  margin-top: 1.325rem;
}

.progress-fill {
  height: 100%;
  border-radius: 0.125rem;
  transition: width 0.3s ease;
}
</style>
