<template>
  <div v-if="isActiveTab">
    <InputComponent id="price"
                    v-model="price"
                    empty-message="Укажите стоимость"
                    label-text="Стоимость"
                    placeholder="Цена в российских рублях"
                    type="number"
                    :hardValidate="hardValidate"/>

    <InputComponent id="location"
                    v-model="location"
                    empty-message="Укажите ваш город"
                    label-text="Местоположение"
                    placeholder="Ваш город"
                    type="text"
                    :hardValidate="hardValidate"/>

    <TextareaComponent empty-message="Без описания нельзя" id="description" label-text="Описание"
                       v-model="description" placeholder="Текст вашего объявления" :max-length="500"
                       :hardValidate="hardValidate"/>
  </div>
  <div v-else>
    <InputComponent id="location"
                    v-model="location"
                    empty-message="Укажите ваш город"
                    label-text="Местоположение"
                    placeholder="Ваш город"
                    type="text"
                    :hardValidate="hardValidate"/>

    <TextareaComponent empty-message="Без описания нельзя" id="description" label-text="Описание"
                       v-model="description" placeholder="Описание" :max-length="500"
                       :hardValidate="hardValidate"/>
  </div>
</template>

<script setup lang="ts">
import {computed, defineEmits, defineProps, ref, toRefs, watch} from "vue";
import InputComponent from "@/components/home/input/InputComponent.vue";
import TextareaComponent from "@/components/home/input/TextareaComponent.vue";

const props = defineProps<{
  activeTab: number,
  hardValidate: boolean
}>();

const emit = defineEmits(['update:location', 'update:description', 'update:price']);
const location = ref("");
const description = ref("");
const price = ref("");

const {activeTab} = toRefs(props);

const isActiveTab = computed(() => {
  return 0 === activeTab.value
});

watch(() => location.value, () => emit('update:location', location.value));
watch(() => description.value, () => emit('update:description', description.value));
watch(() => price.value, () => emit('update:price', price.value));
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .list {
    background-color: themed("background-main");
  }
}

.list {
  padding-bottom: 1.7vw;
  border-radius: 10px;
  margin-top: 2.4vw;
  padding-left: 4.3vw;
  box-sizing: border-box;
  overscroll-behavior: contain;
}
</style>