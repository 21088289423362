<template>
  <div :class="{'upload-area': true, 'center': images.length === 0}">
    <div v-if="images.length === 0" @click="triggerUpload" class="upload-button full" :class="{error: isError}">
      <div style="width: 1.4rem; height: 1.4rem">
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="11.8" x2="24" y2="11.8" stroke="currentColor"/>
          <line x1="12.2" y1="0" x2="12.2" y2="24" stroke="currentColor"/>
        </svg>
      </div>
      <span>Добавить фотографии</span>
    </div>
    <div v-else class="image-list">
      <div v-show="images.length < 7" @click="triggerUpload" class="upload-button small">
        <div style="width: 1.4rem; height: 1.4rem">
          <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <line y1="11.8" x2="24" y2="11.8" stroke="currentColor"/>
            <line x1="12.2" y1="0" x2="12.2" y2="24" stroke="currentColor"/>
          </svg>
        </div>
      </div>
      <div class="images-wrapper">
        <div
            v-for="(image, index) in images"
            :key="index"
            class="image-item"
        >
          <img :src="image" alt="Uploaded image"/>
          <button @click="removeImage(index)" class="remove-btn"><img src="/assets/close.svg" alt="Закрыть"
                                                                      style="width: 2.2rem; height: 2.2rem"></button>
        </div>
      </div>
    </div>
    <input type="file" ref="fileInput" @change="onFileChange" accept="image/*" style="display: none"/>
  </div>
</template>

<script setup lang="ts">
import {defineEmits, defineProps, getCurrentInstance, ref, toRefs, watch} from 'vue';
import {Analytics} from "@/analytics/Analytics";

const emit = defineEmits(['update:images']);
const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const images = ref<string[]>([]);
const fileInput = ref<HTMLInputElement | null>(null);
const props = defineProps<{
  error: boolean,
}>();

const {error} = toRefs(props);
const isError = ref(false);

watch(() => error.value, () => {
  isError.value = true;
});

const triggerUpload = () => {
  fileInput.value?.click();
};

const onFileChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const files = target.files;

  if (files && files.length > 0 && images.value.length < 7) {
    for (const file of Array.from(files)) {
      if (!file.type.startsWith('image/') || file.type.includes('hei')) continue;
      if (file.size > process.env.VUE_APP_MAX_FILE_SIZE * 1024 * 1024) continue;

      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target?.result) {
          const img = new Image();
          img.src = e.target.result as string;

          img.onload = () => {
            if (img.width + img.height > 10000 || img.width / img.height > 20) {
              console.warn(`Изображение ${file.name} превышает допустимые размеры и будет пропущено.`);
              return;
            }

            if (images.value.length < 7) {
              images.value.push(e.target?.result as string);
              emit('update:images', images.value);
              analytics.sendEvent("Image", {
                Action: "Add"
              });
            }
          };
        }
      };

      reader.readAsDataURL(file);
    }
  }

  target.value = '';
};



const removeImage = (index: number) => {
  images.value.splice(index, 1);

  analytics.sendEvent("Image", {
    Action: "Delete"
  });
};
</script>


<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .upload-area {
    display: flex;
    flex-direction: column;

    .upload-button {
      color: themed('button');

      &.error {
        color: themed('accent-red');

        &::before {
          border: 0.125rem dashed themed('accent-red');
        }
      }

      &::before {
        border: 0.125rem dashed themed('button');
      }
    }

    .image-list {
      .images-wrapper {
        .image-item {
          .remove-btn {
            background: rgba(0, 0, 0, 0.48);
            color: white;
          }
        }
      }
    }
  }
}

.center {
  align-items: center;
}

.upload-area {
  display: flex;
  flex-direction: column;

  .upload-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.full {
      width: -webkit-fill-available; /* FIXME crossbrowser */
      height: 5.25rem;
      text-align: center;
      font-size: 1.05rem;
      margin: 0 1.1rem;

      div {
        margin-right: 0.6rem;
      }
    }

    &.small {
      min-width: 5.25rem;
      height: 5.25rem;
      margin-right: 0.625rem;
    }

    &.error {
      &::before {
        border-radius: 0.625rem;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-sizing: border-box;
        pointer-events: none;
        padding: 0.125rem;
      }
    }

    &::before {
      border-radius: 0.625rem;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-sizing: border-box;
      pointer-events: none;
      padding: 0.125rem;
    }
  }

  .image-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    padding-left: 1.1rem;
    padding-right: 1.1rem;

    overflow-x: auto;
    overflow-y: hidden;

    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .images-wrapper {
      display: flex;
      gap: 0.625rem;

      .image-item {
        position: relative;
        width: 5.25rem;
        height: 5.25rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0.625rem;
        }

        .remove-btn {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1.25rem;
          border-radius: 0.625rem;
          opacity: 1;
        }
      }
    }
  }
}
</style>