<template>
  <div class="input-group">
    <label :for="id">{{ labelText }}</label>
    <input :id="id"
           :class="{error: isEmpty}"
           :type="type"
           :placeholder="placeholder"
           :value="modelValue"
           @input="$emit('update:modelValue', $event.target.value) &&
                   analytics.sendEvent('Input',{
                              Field: id
                            });"
           @blur="validate"/>

    <span :class="{'error-message': true, 'visible': isEmpty, 'hidden': !isEmpty }">{{ emptyMessage }}</span>
  </div>
</template>

<script setup lang="ts">
import {defineProps, getCurrentInstance, ref, toRefs, watch} from "vue";
import {Analytics} from "@/analytics/Analytics";

const analytics: Analytics = getCurrentInstance()?.appContext.config.globalProperties.analytics;

const props = defineProps<{
  id: string,
  type: string,
  labelText: string,
  placeholder: string,
  emptyMessage: string,
  modelValue: string,
  hardValidate: boolean
}>();

const {id, type, labelText, placeholder, emptyMessage, modelValue, hardValidate} = toRefs(props);
const isEmpty = ref(false);

const validate = () => {
  isEmpty.value = type.value !== "number" ? modelValue.value.trim() === '' :
      parseInt(modelValue.value) <= 0 || modelValue.value.length == 0;
};

watch(() => hardValidate.value, () => validate());
</script>

<style scoped lang="scss">
@import "@/assets/styles/theme-colors";

@include themify($themes) {
  .input-group {
    label {
      color: themed('text');
    }

    input {
      border: 1px solid themed("accent-gray-5");
      background-color: themed("accent-gray-6");
      color: themed('text');

      &.error {
        border-color: themed("accent-red");
      }

      &:focus {
        border-color: themed("button");
      }
    }

    .error-message {
      color: themed("accent-red");
    }
  }
}

.input-group {
  margin-bottom: 0.40rem;

  label {
    font-size: 0.9rem;
    display: block;
    margin-bottom: 0.3rem;
  }

  label, .error-message {
    margin-left: 0.5rem;
  }

  input {
    width: 100%;
    padding: 0.55rem 0.75rem;
    font-size: 1.05rem;
    border-radius: .65rem;
    box-sizing: border-box;

    &::placeholder {
      color: themed("accent-gray")
    }

    &:focus {
      outline: none;
    }
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }

  .error-message {
    display: block;
    font-size: 0.875rem;
    margin-top: 0.35rem;
  }

  .hidden {
    visibility: hidden;
  }

  .visible {
    visibility: visible;
  }
}
</style>